// Theme colors and variables
$rothko-red: #ff1749;
$picasso-blue: #15212f;
$picasso-blue-50: #b1b4b7;
$picasso-blue-60: #868c93;
$whistler-grey: #d7d2cb;
$whistler-grey-25: #f5f4f2;
$whistler-grey-50: #ebe9e5;
$whistler-grey-75: #e1ddd8;
$van-gogh-yellow: #fff38a;
$monet-green: #7ce0d3;
$matisse-blue: #8dc8e8;
$font-sulsans: 'sul-sans', helvetica, sans-serif;
$font-boing: 'boing', helvetica, sans-serif;
$main-navigation-hover-color: #7a7d83;

// todo: We should think how we can use the $spacers map instead;
$spacer-sm: 0.625rem;
$spacer-m: 1.25rem;

// --- Bootstrap overrides ---
// Colors
$colors: (
  'rothko-red': $rothko-red,
  'picasso-blue': $picasso-blue,
  'whistler-grey': $whistler-grey,
  'van-gogh-yellow': $van-gogh-yellow,
  'monet-green': $monet-green,
  'matisse-blue': $matisse-blue,
);

$theme-colors: (
  'primary': $rothko-red,
  'secondary': $whistler-grey,
  'success': $monet-green,
  'danger': $rothko-red,
);

// Options
$enable-rounded: false;

// Font, line-height, and color for body text, headings, and more.
$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 0.875);

$text-muted: #6c757d;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $picasso-blue;
$yiq-text-light: #fff;

// // Body
$body-bg: $whistler-grey-25;
$body-color: $picasso-blue;

// Links
$link-color: $picasso-blue;
$link-decoration: none;
$link-hover-color: $rothko-red;
$link-hover-decoration: none;

// Fonts
$font-family-base: $font-sulsans;

// Shared variables that are reassigned to `$input-` and `$btn-` specific variables
$input-btn-border-width: 0px;

$input-btn-padding-y: 0.75rem;
$input-btn-padding-x: 0.71875rem;
$input-btn-line-height: 1.5;

$input-btn-padding-y-sm: 0.5rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-line-height-sm: 1.5;

// Buttons
$btn-primary-background: $rothko-red;
$btn-primary-background-hovered: #ca143b;
$btn-primary-background-active: #ff5177;
$btn-primary-color: #fff;

$btn-secondary-background: $whistler-grey;
$btn-secondary-background-hovered: #c8c1b7;
$btn-secondary-background-active: #e1ded8;
$btn-secondary-color: $picasso-blue;

$btn-tertiary-background: $picasso-blue;
$btn-tertiary-background-hovered: #05172b;
$btn-tertiary-background-active: #505963;
$btn-tertiary-color: #fff;

$btn-action-background: $picasso-blue;
$btn-action-background-active: $rothko-red;

$btn-disabled-background: #f5f4f2;
$btn-disabled-color: $whistler-grey;

$btn-height-border: $input-btn-border-width * 2;
$btn-height-content: ($font-size-sm * $input-btn-line-height);
$btn-height: calc(#{$btn-height-content + ($input-btn-padding-y * 2)} + #{$btn-height-border});
$btn-height-content-sm: ($font-size-sm * $input-btn-line-height-sm);
$btn-height-sm: calc(
  #{$btn-height-content-sm + ($input-btn-padding-y-sm * 2)} + #{$btn-height-border}
);

// Forms
$input-color: $picasso-blue;
$input-border-color: $whistler-grey-25;
$input-placeholder-color: $picasso-blue-50;

$input-group-addon-color: $picasso-blue;
$input-group-addon-bg: $whistler-grey-25;

$custom-control-indicator-bg: #fff;

// Dropdowns
$dropdown-border-color: #fff;
$dropdown-divider-bg: $whistler-grey-50;
$dropdown-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);

$dropdown-link-color: $picasso-blue;
$dropdown-link-hover-color: darken($picasso-blue, 5%);
$dropdown-link-hover-bg: $whistler-grey-25;

$dropdown-link-active-color: $picasso-blue;
$dropdown-link-active-bg: $whistler-grey-25;

$dropdown-link-disabled-color: $picasso-blue-60;

$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;

$dropdown-header-color: $picasso-blue;

// Pagination
$pagination-color: $picasso-blue;
$pagination-bg: #fff;
$pagination-border-color: $whistler-grey-50;

$pagination-hover-color: $picasso-blue;
$pagination-hover-bg: #fff;
$pagination-hover-border-color: $whistler-grey-75;

// Modals
$modal-content-bg: $whistler-grey-50;
$modal-content-border-color: $whistler-grey-50;
$modal-header-border-color: $whistler-grey-50;
$modal-footer-border-color: $whistler-grey-50;

// Badges
$badge-border-radius: 12px;

// Breadcrumbs
$breadcrumb-padding-y: $spacer-sm;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: $spacer-sm;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $picasso-blue-60;
$breadcrumb-active-color: $picasso-blue;

// Card
$card-border-width: 0;
$card-border-radius: 8px;
$card-inner-border-radius: ($card-border-radius - $card-border-width);

$card-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14);

// Carousel
$carousel-control-color: $picasso-blue;
$carousel-control-width: 15%;
$carousel-control-opacity: 1;
$carousel-indicator-width: 12px;
$carousel-indicator-height: 12px;
$carousel-indicator-spacer: 10px;
$carousel-indicator-active-bg: $whistler-grey;
$carousel-control-icon-width: 45px;
$carousel-control-prev-icon-bg: none;
$carousel-control-next-icon-bg: none;

// Navs
$nav-link-padding-y: 1rem;
$nav-link-padding-x: 1rem;

// Toasts
$toast-background-color: $rothko-red;
$toast-color: #fff;
$toast-border-color: $rothko-red;
$toast-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
$toast-border-width: 2px;
$toast-header-color: #fff;
$toast-header-background-color: $rothko-red;
$toast-header-border-color: $rothko-red;
