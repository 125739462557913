@import 'vars';

@import './red-grey-fonts';

.page-home {
  & > section:nth-of-type(odd) {
    background: $white;
  }
}

.toast-header {
  .text-muted,
  .close {
    color: #fff;
  }

  .close:not(:disabled):not(.disabled):hover,
  .close:not(:disabled):not(.disabled):focus {
    color: #fff;
    opacity: 0.75;
  }
}

.toast {
  position: relative;
  background-clip: border-box;

  &:hover {
    border-color: #fff;
  }

  .close {
    color: $toast-color;
    font-weight: 500;
    text-shadow: none;
    right: 5px;
    top: 0;
    opacity: 0;
    transition: opacity 0.2s;
    position: absolute;

    &:hover {
      opacity: 1 !important;
    }

    &:hover > span {
      color: $toast-color;
    }
  }

  &:hover .close {
    opacity: 1;
  }
}

.toast-body {
  text-align: center;
  display: block;
}

html {
  font-size: 16px;
}

body {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Theme specifics (not configurable via bootstrap overrides)
.container {
  max-width: 1170px;
}

.app > hr {
  margin: 0 auto;
  max-width: (1170px - $grid-gutter-width);
  border-color: $whistler-grey-50;
}

// Badge
.badge {
  min-width: 20px;
  border-radius: $badge-border-radius;
}

// Utilities
.center-cropped {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-muted {
  color: $text-muted;
}

.text-success {
  color: $monet-green;
}

.text-primary {
  color: theme-color('primary');
}

// Form
// .input-group-prepend {
//   display: block;
//   position: absolute;
//   z-index: 9;
//   width: 45px;
//   top: 2px;
//   bottom: 2px;
//   left: 1px;
// }

// .input-group-prepend + .form-control {
//   padding-left: 41px;
// }

// .input-group-text {
//   border: none;
// }

// Custom checkboxes and radios
.form-check {
  position: relative;
  display: block;
  min-height: ($font-size-base * $line-height-base);
  padding-left: $custom-control-gutter;
}

.form-check-inline {
  display: inline-flex;
  margin-right: $custom-control-spacer-x;
}

.form-check-input {
  position: absolute;
  z-index: -1; // Put the input behind the label so it doesn't overlay text
  opacity: 0;

  &:checked ~ .form-check-label::before {
    color: $custom-control-indicator-checked-color;
    @include gradient-bg($custom-control-indicator-checked-bg);
    @include box-shadow($custom-control-indicator-checked-box-shadow);
  }

  &:focus ~ .form-check-label::before {
    // the mixin is not used here to make sure there is feedback
    box-shadow: $custom-control-indicator-focus-box-shadow;
  }

  &:active ~ .form-check-label::before {
    color: $custom-control-indicator-active-color;
    background-color: $custom-control-indicator-active-bg;
    @include box-shadow($custom-control-indicator-active-box-shadow);
  }

  &:disabled {
    ~ .form-check-label {
      color: $custom-control-label-disabled-color;

      &::before {
        background-color: $custom-control-indicator-disabled-bg;
      }
    }
  }
}

.form-check-label {
  position: relative;
  margin-bottom: 0;

  // Background-color and (when enabled) gradient
  &::before {
    position: absolute;
    top: (
      ($font-size-base * $line-height-base - $custom-control-indicator-size) / 2
    );
    left: -$custom-control-gutter;
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    pointer-events: none;
    content: '';
    user-select: none;
    background-color: $custom-control-indicator-bg;
    @include box-shadow($custom-control-indicator-box-shadow);
  }

  // Foreground (icon)
  &::after {
    position: absolute;
    top: (
      ($font-size-base * $line-height-base - $custom-control-indicator-size) / 2
    );
    left: -$custom-control-gutter;
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    content: '';
    background-repeat: no-repeat;
    background-position: center center;
    background-size: $custom-control-indicator-bg-size;
  }
}

.form-check-checkbox {
  .form-check-label::before {
    @include border-radius($custom-checkbox-indicator-border-radius);
  }

  .form-check-input:checked ~ .form-check-label {
    &::before {
      @include gradient-bg($custom-control-indicator-checked-bg);
    }
    &::after {
      background-image: $custom-checkbox-indicator-icon-checked;
    }
  }

  .form-check-input:indeterminate ~ .form-check-label {
    &::before {
      @include gradient-bg($custom-checkbox-indicator-indeterminate-bg);
      @include box-shadow($custom-checkbox-indicator-indeterminate-box-shadow);
    }
    &::after {
      background-image: $custom-checkbox-indicator-icon-indeterminate;
    }
  }

  .form-check-input:disabled {
    &:checked ~ .form-check-label::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
    &:indeterminate ~ .form-check-label::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
  }
}

.form-check-radio {
  .form-check-label::before {
    border-radius: $custom-radio-indicator-border-radius;
  }

  .form-check-input:checked ~ .form-check-label {
    &::before {
      @include gradient-bg($custom-control-indicator-checked-bg);
    }
    &::after {
      background-image: $custom-radio-indicator-icon-checked;
    }
  }

  .form-check-input:disabled {
    &:checked ~ .form-check-label::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
  }
}

.modal {
  .modal-content {
    background-clip: border-box;
  }
}

// make the modals full screen on mobile
@include media-breakpoint-down(md) {
  .modal-dialog {
    margin: 0;
    width: 100%;
    max-width: initial;
  }

  .modal-header {
    padding-top: $_navbar-padding-y;
    padding-bottom: $_navbar-padding-y;
  }

  .modal-content {
    min-height: 100vh;
  }

  .modal-content {
    border: none;
    border-radius: 0;
  }

  .modal-title,
  .modal-header .close {
    height: #{$_navbar-height - ($_navbar-padding-y * 2)};
    line-height: #{$_navbar-height - ($_navbar-padding-y * 2)};
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .toast-container {
    display: none;
  }
}

.af-dot {
  height: $_af-dot-size;
  width: $_af-dot-size;
  background-color: #ff1749;
  border-radius: 50%;
  display: inline-block;
}

.dot-leaders {
  list-style: none;
  padding: 0;

  li {
    display: flex;
    flex-direction: row;
    overflow: hidden;

    &::after {
      content: '.....................................................................................................';
      height: 1em;
      order: 2;
      flex-shrink: 1;
      overflow: hidden;
      margin: 0 10px;
    }
  }

  li > *:first-child {
    order: 1;
    flex-shrink: 0;
  }

  li > *:last-child {
    order: 3;
    flex-shrink: 0;
  }
}

.page-link,
a.dropdown-item,
a.nav-link,
.basket-item a {
  text-decoration: none;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
}

.page-link {
  padding: 0;
  line-height: 41px;
  height: 41px;
  width: 41px;
  text-align: center;
  margin-left: 5px;
  margin-bottom: 5px;

  svg {
    vertical-align: inherit;
  }
}

.artist .bi-location,
.rating .bi {
  color: $rothko-red;
}

section {
  padding-top: 42px;
  padding-bottom: 42px;
  overflow: hidden;

  .header-section,
  .container > p {
    margin-bottom: $spacer-sm;
  }

  .showcase-section__gallery,
  .carousel-scroll {
    margin-top: 2 * $spacer;
  }
}

.bi {
  pointer-events: none;
}

@include media-breakpoint-up(lg) {
  section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .toast-container {
    right: 1%;
  }
}
