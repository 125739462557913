@font-face {
  font-family: 'sul-sans';
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url('https://d2m7ibezl7l5lt.cloudfront.net/fonts/optimized/sul_sans_regular-webfont.woff2')
      format('woff2'),
    url('https://d2m7ibezl7l5lt.cloudfront.net/fonts/optimized/sul_sans_regular-webfont.woff')
      format('woff');
}

@font-face {
  font-family: 'sul-sans';
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  src: url('https://d2m7ibezl7l5lt.cloudfront.net/fonts/optimized/sul_sans_bold-webfont.woff2')
      format('woff2'),
    url('https://d2m7ibezl7l5lt.cloudfront.net/fonts/optimized/sul_sans_bold-webfont.woff')
      format('woff');
}

@font-face {
  font-family: 'boing';
  font-display: swap;
  src: url('https://d2m7ibezl7l5lt.cloudfront.net/fonts/optimized/boing-bold-subset.woff2')
      format('woff2'),
    url('https://d2m7ibezl7l5lt.cloudfront.net/fonts/optimized/boing-bold-subset.woff')
      format('woff');
}

@font-face {
  font-family: 'AF-icons';
  src: url('https://d2m7ibezl7l5lt.cloudfront.net/fonts/icons/20240828/AF-icons.woff?owlod')
      format('woff'),
    url('https://d2m7ibezl7l5lt.cloudfront.net/fonts/icons/20240828/AF-icons.ttf?owlod')
      format('truetype'),
    url('https://d2m7ibezl7l5lt.cloudfront.net/fonts/icons/20240828/AF-icons.eot?owlod')
      format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
