/**
 * Created by Sergiu Ghenciu on 23/12/2017
 */
@import './red-grey-vars';
@import '../vendors/bootstrap/scss/functions';
@import '../vendors/bootstrap/scss/variables';
@import '../vendors/bootstrap/scss/mixins';

$_navbar-height: 74px;
$_navbar-padding-y: 15px;

$_subnav-height: 74px;
$_subnav-padding-y: 17px;

$_artwork-by-artist-height: 164px;
$_artwork-by-artist-padding-y: 40px;

$_sortbar-height: 64px;
$_sortbar-padding-y: 12px;

$_basket-cover-size: 100px;

$_af-dot-size: 150px;
